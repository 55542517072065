import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionNoBG from "../components/section_no_bg"
import "./blog-post.module.scss"

export default function BlogPost(props) {
  const post = props.data.markdownRemark
  const acc = props.pageContext.acc

  console.log(acc)

  return (
    <Layout
      pageTitle={post.frontmatter.title}
      parentPage={acc ? "accounting" : "feed"}
      parentPageTitle={acc ? "Account & Advisory" : "Feed"}
      secondParentPage={acc ? "open-positions" : null}
      secondParentPageTitle={acc ? "Open positions" : null}
    >
      <Seo
        title={post.frontmatter.title}
        {...(post.frontmatter.image
          ? { image: `${post.frontmatter.image}` }
          : {})}
        {...(post.frontmatter.description
          ? { description: `${post.frontmatter.description}` }
          : {})}
      />
      <SectionNoBG>
        <div className="post-header mt-5 mb-4">
{/*           {post.frontmatter.tags?.map((tag, i) => [
            <button
              type="button"
              className="team-tag disabled btn btn-primary btn-sm"
              key={i}
            >
              {tag}
            </button>,
          ])} */}
          <h1 className="pt-3">{post.frontmatter.title}</h1>
          <p className="my-4 dimmed-text">
            {post.frontmatter.date}{" "}
            {post.frontmatter.author && <> - By {post.frontmatter.author}</>}
          </p>
          <p className="lead">{post.frontmatter.description}</p>
        </div>
        {post.frontmatter.image && (
          <div className="post-image mb-5 mt-1">
            <img
              alt={post.frontmatter.title}
              src={`${post.frontmatter.image}`}
              className="post-image pb-3"
            ></img>
          </div>
        )}
        <div className="post-container">
          <div
            className="my-0"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </SectionNoBG>
    </Layout>
  )
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        author
        title
        description
        image
        layout
        date(formatString: "MM/DD/YYYY")
      }
      html
    }
  }
`
